import React from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import search from '../assets/search.png'
import darkSearch from '../assets/search_icon_dark.png'

export class SearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.props.handleSearch(event.target.value)
    }
  }

  onChange = (event) => {
    this.props.syncInput(event.target.value)
  }
 
  render() {
    return (
      <Row className='search-row'>
        <Form.Control
          onKeyPress={this.handleKeyPress}
          as='input'
          type='text'
          placeholder='Search...'
          value={this.props.input}
          onChange={event => this.props.syncInput(event.target.value)}
          className='search-input'
        />
        <img src={darkSearch} className='search-icon dark' alt='search' />
        <img src={search} className='search-icon' alt='search' />
      </Row>
    )
  }
}
