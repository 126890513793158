import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Collapse from 'react-bootstrap/Collapse'
import { Chart, Line } from 'react-chartjs-2'
import { ChartHeader } from './ChartHeader'
import { lineColors } from '../utils/lineColors'
import { CSSTransition } from 'react-transition-group'

export class ChartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chart: {
        datasets: [],
        labels: []
      },
      aspectRatio: 1,
      displayYLabel: true,
      smallScreen: false
      //smooth: false
    }

    Chart.pluginService.register({
      afterDraw: function (chart, easing) {
        // Plugin code.
      }
    })

    Chart.scaleService.updateScaleDefaults('linear', {
      ticks: {
        min: 0,
        max: 100
      }
    })

    this.updateChart()
  }

  componentDidMount() {
    const width = this.container.offsetWidth

    if (width < 620) {
      this.setState({ aspectRatio: 0.5, displayYLabel: false, smallScreen: true })
    }
  }

  updateChart(smooth = false) {
    const smoothScale = 3
    const data = this.props.data

    const defaults = {
      fill: true,
      pointRadius: 0,
      backgroundColor: 'transparent',
      borderWidth: 1.5,
      hidden: false,
    }

    let labels = []
    let datasets = []

    data.length > 0 && data.forEach((keywordData, index) => {
      // This is where we process each keyword
      let keyword = Object.keys(keywordData)[0]
      let currentYear = 0
      let currentYearData = []
      let tempDataSet = []

      
      
      keywordData[keyword].dataset.forEach((object, datasetIndex) => {
        const { ts, score } = object
        const date = new Date(object.ts * 1000)
        const year = date.getFullYear()
        if (currentYear !== year) {
          tempDataSet.push(...currentYearData)

          currentYearData = []
          if (index === 0) {
            labels.push(this.state.smallScreen === true ? `'${String(year).slice(-2)}` : String(year))
          }
          currentYear = year
        } else {
          if (index === 0) {
            if (smooth === true && datasetIndex % smoothScale === 0) {
              labels.push("")
            } else if (smooth === false) {
              labels.push("")
            }
          }
        }

        if (smooth === true && datasetIndex % smoothScale === 0) {
          currentYearData.push(score)
        } else if (smooth === false) {
          currentYearData.push(score)
        }
        
      })
      
      // data for current year
      tempDataSet.push(...currentYearData)
      datasets.push({
        ...defaults,
        data: tempDataSet,
        label: keyword
      })
    })

    datasets.forEach((dataset, i) => {
      dataset.borderColor = lineColors[i] || '#497DFF'
    })

    this.setState({
      chart: {
        labels,
        datasets
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      // Only update chart if we actually changed the data
      this.updateChart()
    }
  }

  
  render() {

    const chartOptions = {
      onResize: (chart, size) => {
        if (size.width < 620 && this.state.aspectRatio !== 0.5 ) {
          this.setState({ aspectRatio: 0.5, displayYLabel: false })
        } else if (size.width >= 620 && this.state.aspectRatio !== 1) {
          this.setState({ aspectRatio: 1, displayYLabel: true })
        }
      },
      aspectRatio: this.state.aspectRatio,
      animation: false,
      maintainAspectRatio: false,
      showTooltips: false,
      legend: {
        display: false
      },
      title: {
        display: false,
      },
      hover: {
        mode: 'label',
        intersect: false
      },
      tooltips: {
        backgroundColor: '#fff',
        titleFontFamily: "'Proxima Nova', sans-serif",
        titleFontColor: '#555',
        labelTextColor: '#555',
        borderColor: '#d1ddfd',
        borderWidth: 1,
        xPadding: 8,
        displayColors: false,
        mode: 'label',
        intersect: false,
        callbacks: {
          labelTextColor: () => '#555',
          // use label callback to return the desired label
          label: ({ yLabel: label }) => label,
          // remove title
          title: () => {}
        }
      },
      scales: {
        yAxes: [{
          display: true,
          gridLines: {
            display: true,
            drawBorder: false,
            zeroLineColor: 'rgb(223, 223, 223)',
            zeroLineWidth: 0.8,
            tickMarkLength: 0
          },
          ticks: {
            display: this.state.displayYLabel,
            fontSize: 14,
            padding: 20,
            fontColor: '#dbdbdb',
            stepSize: 25
          }
        }],
        xAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawBorder: true
          },
          ticks: {
            display: true,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            fontSize: 14,
            padding: 20,
            fontColor: '#dbdbdb',
            maxTicksLimit: 2
          }
        }]
      }
    }

    const { data, embed } = this.props

    const rowStyle = {
      position: 'relative',
      borderRadius: embed ? 0 : 6,
      paddingLeft: 20,
      paddingRight: 20
    }

    return (
      <Collapse in={this.props.errMsg.length === 0 ? true : false}>
        <Row className='chart-row' style={rowStyle}>
          <CSSTransition in={data.length === 0} timeout={300} classNames='fade' unmountOnExit>
            <Col className='spinner-col'>
              <Spinner animation='grow' style={{color: '#497DFF'}} />
            </Col>
          </CSSTransition>
          <Col style={{zIndex: 98}}>
            <ChartHeader
              keywordArray={this.props.keywordArray}
              /*setSmooth={() => {
                const toSmooth = !this.state.smooth
                this.setState({ smooth: toSmooth })
                this.updateChart(toSmooth)
              }}
              smooth={this.state.smooth}*/
              query={this.props.query}
              data={data}
              embed={embed}
            />
            <Row ref={el => (this.container = el)}>
              <Line
                data={this.state.chart} 
                height={null}
                width={null}
                className='linechart'
                options={chartOptions}
              />
            </Row>
          </Col>
        </Row>
      </Collapse>
    )
  }
}