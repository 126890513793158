export const keywords = [
  'oat milk',
  'juul and vape',
  'bitcoin',
  'cbd',
  'solo travel',
  'bone broth',
  'remote jobs',
  'acv gummies and acv pills',
  'fortnite and apex legends',
  'axe throwing'
]