import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import close from '../assets/x.png'

export class EmbedModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopied: false
    }

    this.link = null
  }

  copyToClipboard = () => {
    const link = this.link
    link.select()
    document.execCommand('copy')
    this.setState({ isCopied: true })
    setTimeout(() => {
      this.setState({ isCopied: false })
    }, 2 * 1000)
  }

  render() {
    return (
      <Modal centered show={this.props.show} size='xl' keyboard={true} onHide={this.props.hideModal}>
        <Col className='modal-wrapper'>
          <Row>
            <Col xs={12} lg={4} className='code-col'>
              <h4 className='modal-title'>Code</h4>
              <p className='grey'>Paste this into any HTML.</p>
              <div className='code-div'>
                <p style={{ wordBreak: 'break-word' }}>
                  <input type='text' style={{ opacity: 0, width: 1, margin: 0, padding: 0, border: 'none' }} ref={(el) => this.link = el} value={`<iframe src="${`https://reddit-trends.now.sh/embed/${this.props.query}`}" height="400px" width="100%"" frameborder="false" scrolling="no"></iframe>`}></input>
                  &lt;iframe src="{`https://reddit-trends.now.sh/embed/${this.props.query}`}" height="400px" width="100%"" frameborder="false" scrolling="no"&gt;&lt;/iframe&gt;
                </p>
              </div>
              <Button
                className='copy-button'
                style={{marginTop: 20}}
                onClick={this.copyToClipboard}
              >
                {
                  this.state.isCopied === false ? 'Copy to clipboard' : 'Copied to clipboard'
                }
              </Button>
            </Col>
            <Col>
              <h4 className='modal-title'>Preview</h4>
              <p className='grey'>This data is static and will not update.</p>
              <div className='diagram-div'>
                <iframe src={`https://reddit-trends.now.sh/embed/${this.props.query}`} height='400px' width='100%' frameborder='false' scrolling='no'></iframe>
              </div>
            </Col>
          </Row>
        </Col>
        <img src={close} onClick={this.props.hideModal} className='modal-close' alt='close' />
      </Modal>
    )
  }
}