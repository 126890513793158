import React from 'react'
import Row from 'react-bootstrap/Row'
import FormControl from 'react-bootstrap/FormControl'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Api from '../utils/Api'

export class Subscribe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }

    this.api = new Api()
  }

  handleSubmit = () => {
    const body = {
      'email': this.state.email
    }

    this.api.subscribe(body)
    .then(res => {
      console.log(res)
    })
  }
  
  render() {
    return (
      <Row className='subscribe-row'>
        <Col xs={12} lg className='subscribe-col'>
          <div className='subscribe-div'>
            <h3 className='subscribe'>
              Get interesting trends every month
            </h3>
            <p className='unsubscribe'>
              You can unsubscribe any time, obviously.
            </p>
          </div>
        </Col>
        <Col xs={12} lg className='input-col'>
          <InputGroup className='email-input-group'>
            <FormControl
              placeholder='curious@me.com'
              as='input'
              type='email'
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              className='email-input'
            />
            <InputGroup.Append className='appendix'>
              <Button className='submit' onClick={this.handleSubmit}>Submit</Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col xs={12} className='mobile-submit-col'>
          <Row>
            <Button className='mobile-submit' onClick={this.handleSubmit}>Submit</Button>
          </Row>
        </Col>
      </Row>
    )
  }
}
