import React from 'react'
import { BrowserRouter as Router, Route, useParams, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/styles/App.scss'
import { MainPage } from './components/MainPage'
import bgBottom from './assets/bg_bottom.svg'
import bgTop from './assets/bg_top.svg'

function GetQuery({ embed }) {
  const { query } = useParams();

  return <MainPage query={query} embed={embed} />
}



function App() {
  return (
    <div style={{position: 'relative', minHeight: '100%', width: '100vw', overflowX: 'hidden'}}>
      <link rel="shortcut icon" type="image/x-icon" href="https://meetglimpse.com/favicon.ico" />
      <div className='topbg' style={{ backgroundImage: `url(${bgTop})` }}></div>
      <Router>
        <Route path='/' component={MainPage} exact/>
        <Switch>
          <Route path='/embed/:query'>
            <GetQuery embed={true} />
          </Route>
          <Route path='/:query'>
            <GetQuery embed={false} />
          </Route>
        </Switch>
      </Router>
      <div className='bottombg' style={{ backgroundImage: `url(${bgBottom})` }}></div>
    </div>
  )
}

export default App
