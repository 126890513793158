import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import info from '../assets/i.png'
import embed from '../assets/embed.png'
import share from '../assets/share.png'
import logo from '../assets/glimpse-blue.png'
import { InfoModal } from './InfoModal'
import { ShareModal } from './ShareModal'
import { EmbedModal } from './EmbedModal'
import { DownloadPopover } from './DownloadPopover'
import { lineColors } from '../utils/lineColors'

export class ChartHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfoModal: false,
      showEmbedModal: false,
      showShareModal: false,
      isPopoverOpen: false,
    }
  }

  togglePopover = () => {
    this.setState({ isPopoverOpen: !this.state.isPopoverOpen })
  }

  closePopover = () => {
    this.setState({ isPopoverOpen: false })
  }

  getMentions = () => {
    const keywordData = this.props.data[0]
    const keyword = Object.keys(keywordData)[0]
    let mentions = keywordData[keyword].num_mentions

    if (mentions > 999999) {
      mentions = (mentions / 1000000).toFixed(1).toString() + 'M'
    } else if (mentions > 999 && mentions < 1000000) {
      mentions = (mentions / 1000).toFixed(1).toString() + 'K'
    }

    return mentions
  }

  render() {
    const { keywordArray, data } = this.props
    String.prototype.capitalize = function(){
      return this.replace( /(^|\s)([a-z])/g , function(m,p1,p2){ return p1+p2.toUpperCase() } )
    }

    return (
      <>
        <Row className='chart-top'>
          <Col className='title-col'>
            {
              keywordArray.map((kw , i)=> {
                return (
                  <span className='chart-title' style={{color: lineColors[i] || '#497DFF'}} key={i}>
                    {kw.capitalize()}{i === keywordArray.length - 1 ? null : ', '}
                  </span>
                )
              })
            }
          </Col>
          <Col style={{paddingRight: 0}} className='action-col'>
            <div className='title-wrapper'>
              { 
                !this.props.embed ? (
                <>
                <span className='action-wrapper hide-on-mobile' style={{position: 'relative'}}>
                  <DownloadPopover
                    keywordArray={this.props.keywordArray}
                    isPopoverOpen={this.state.isPopoverOpen}
                    togglePopover={this.togglePopover}
                    closePopover={this.closePopover}
                  />
                  <span className='chart-icon-span' onClick={() => this.setState({ showEmbedModal: true })}>
                    <img
                      alt='embed'
                      className='chart-icon embed'
                      src={embed}
                    />
                  </span>
                  <span className='chart-icon-span' onClick={() => this.setState({ showShareModal: true })}>
                    <img
                      alt='share'
                      className='chart-icon share'
                      src={share}
                    />
                  </span>
                </span>
                {/* <span className='action-wrapper smooth'>
                <span>smooth</span>
                  {
                    this.props.smooth === true ?
                    <span className='checkbox checked' onClick={this.props.setSmooth}>
                      <img
                        alt='checked smooth'
                        src={check}
                        className='check'
                      />
                    </span>
                    :
                    <span className='empty checkbox' onClick={this.props.setSmooth}></span>
                  }
                </span> */}
                </>
              )
              :
              <a href='https://meetglimpse.com/' target='_blank'>
                <img src={logo} className='blue-logo' alt='glimpse' />
              </a>
            }
          </div>
        </Col>
        </Row>
        <Row className='second-row'>
          <Col>
          <Row>
            <span><p style={{marginBottom: 0}}>Discussion over time</p></span>
            {
              !this.props.embed &&
              <span>
                <img
                  alt='info'
                  src={info}
                  className='info'
                  onClick={() => this.setState({ showInfoModal: true })}
                />
              </span>
            }
            {
              (keywordArray.length === 1 && data.length > 0) &&
              <>
                <span className='separator' style={{marginLeft: 10, marginRight: 10}}>|</span>
                <span style={{display: 'inline-block'}}>
                  <p style={{marginBottom: 0}}>
                  {` ${this.getMentions()} mentions last month`}
                  </p>
                </span>
              </>
            }
            </Row>
          </Col>
        </Row>
        <InfoModal
          show={this.state.showInfoModal}
          hideModal={() => this.setState({ showInfoModal: false })}
        />
        <EmbedModal 
          show={this.state.showEmbedModal}
          hideModal={() => this.setState({ showEmbedModal: false })}
          query={this.props.query}
        />
        <ShareModal 
          show={this.state.showShareModal}
          hideModal={() => this.setState({ showShareModal: false })}
          query={this.props.query}
        />
        {/* <DownloadModal
          show={this.state.showDownloadModal}
          clearDownload={this.clearDownload}
          data={this.props.data}
          query={this.props.query}
          isReadyToDownload={this.state.isReadyToDownload}
          resetState={this.resetState}
        /> */}
      </>
    )
  }
}