import React from 'react'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import close from '../assets/x.png'

export class InfoModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  render() {
    return (
      <Modal centered show={this.props.show} keyboard={true} onHide={this.props.hideModal}>
        <Col className='modal-wrapper'>
          <h4 className='modal-title'>Discussion over time</h4>
          <p className='explanation'>
            Numbers represent discussion relative to the highest point on the chart for the given region and time.
            A value of 100 is the peak popularity for the term.
            A value of 50 means that the term is half as popular.
            A score of 0 means there was not enough data for this term.
          </p>
        </Col>
        <img src={close} onClick={this.props.hideModal} className='modal-close' alt='close' />
      </Modal>
    )
  }
}