import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from '../assets/email-logo_white.png'

export class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  
  render() {
    return (
      <Row className='footer-row'>
        <Col sm={12} md={'auto'}>
          <a href='https://meetglimpse.com/'>
            <img src={logo} className='footer-logo' alt='glimpse' />
          </a>
        </Col>
        <Col sm={12} md={'auto'} className='footer-el'>
          <a href=''>
            contact
          </a>
        </Col>
        <Col sm={12} md={'auto'} className='footer-el'>
          <a href='https://meetglimpse.com/terms.html'>
            terms of service
          </a>
        </Col>
        <Col sm={12} md={'auto'} className='footer-el'>
          <a href='https://meetglimpse.com/privacy.html'>
            privacy policy
          </a>
        </Col>
        <Col sm={12} md={'auto'} className='statement'>
          This site is not affiliated with Reddit.com&trade;.
        </Col>
      </Row>
    )
  }
}
