import axios from 'axios'
import { apiRoot } from './config.js'

export default class Api {
  
  get(path) {
    return axios.get(apiRoot + path, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    })
    .then(response => {
      return response.data
    })
    .catch(error => error.response)
  }

  post(path, body) {
    return axios.post(apiRoot + path, body)
  }

  put(path, body) {
    return axios.put(apiRoot + path, body)
  }

  delete(path, body) {
    return axios.delete(apiRoot + path, { data: body })
  }

  fetchData(keywords) {
    return this.get(`/api/reddit/trends?keywords=${keywords}`)
  }

  subscribe(body) {
    return this.post('/api/reddit/download', body)
  }
  
}
