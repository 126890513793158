import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import download from '../assets/download.png'
import Api from '../utils/Api'

export class DownloadPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      isPopoverOpen: false
    }

    this.api = new Api()
  }

  handleSubmit = () => {
    const body = {
      'email': this.state.email,
      'keywords': this.props.keywordArray
    }

    this.api.subscribe(body)
  }

  render() {
    return (
      <Popover
        className='popover'
        isOpen={this.props.isPopoverOpen}
        position={'bottom'}
        arrowStyle={{ zIndex: 100, color: 'white' }}
        padding={38}
        onClickOutside={this.props.closePopover}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
              position={'bottom'}
              targetRect={targetRect}
              popoverRect={popoverRect}
              arrowColor={'white'}
              arrowSize={10}
          >
            <p>
              The CSV spreadsheet will be sent to your email.
            </p>
            <InputGroup className='csv-email-input-group'>
              <FormControl
                placeholder='curious@me.com'
                as='input'
                type='email'
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                className='email-input csv-email'
              />
              <InputGroup.Append className='appendix'>
                <Button
                  className='submit'
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Col xs={12} className='mobile-submit-col' style={{paddingRight: 0, paddingLeft: 0}}>
              <Row>
                <Button
                  className='mobile-submit'
                  style={{marginRight: 0, marginLeft: 0}}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </Row>
            </Col>
          </ArrowContainer>
      )}
      >
        <span className='chart-icon-span' onClick={this.props.togglePopover} style={{position: 'relative'}}>
          <img
            alt='download'
            className='chart-icon margin download'
            src={download}
          />
        </span>
       </Popover> 
    )
  }
}