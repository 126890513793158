import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import close from '../assets/x.png'

export class ShareModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopied: false
    }
  }

  copyToClipboard = () => {
    const link = this.link
    link.select()
    document.execCommand('copy')
    this.setState({ isCopied: true })
    setTimeout(() => {
      this.setState({ isCopied: false })
    }, 2 * 1000)
  }

  render() {
    return (
      <Modal centered show={this.props.show} className='share-modal' size='sm' keyboard={true} onHide={this.props.hideModal}>
        <Col className='modal-wrapper share'>
          <h4 className='modal-title'>Share</h4>
          <Row className='share-row'>
            <Col xs={12} lg={8}>
              <input
                className='link'
                ref={(el) => this.link = el}
                value={`https://reddit-trends.now.sh/${this.props.query}`} 
              />
            </Col>
            <Col className='copy-button-col'>
              <Button
                className='copy-button'
                onClick={this.copyToClipboard}
              >
                {
                  this.state.isCopied === false ? 'Copy to clipboard' : 'Copied to clipboard'
                }
              </Button>
            </Col>
          </Row>
        </Col>
        <img src={close} onClick={this.props.hideModal} className='modal-close' alt='close' />
      </Modal>
    )
  }
}