import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import grid from '../assets/grid.svg'

export class BottomPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  
  render() {
    return (
      <Row className='info-row'>
        <Col sm={12} lg={6} className='grid-col'>
          <img src={grid} className='grid-img' alt='' />
        </Col>
        <Col sm={12} lg={6} className='text-col'>
          <div>
            <h2 className='info-title'>
              Why is This Useful?
            </h2>
            <p className='info-text'>
              At billions of monthly visits, Reddit.com is the #6 most popular site on the internet globally.
            </p>
            <p className='info-text'>
              Unlike other discussion sites, this site encompases nearly all demographics and topics.
              This means that the data accurately represents the world that is changing around us.
              This tool allows these changes to be seen through the lens of data.
            </p>
            <p className='info-text'>
              Similar to Google Trends, this tool can be used to spot trends early. In many cases, trends become popular on this site first, before on sites like Google.
            </p>
          </div>
        </Col>
      </Row>
    )
  }
}
