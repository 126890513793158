import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { SearchInput } from './SearchInput'
import { ChartComponent } from './Chart'
import { Keywords } from './Keywords'
import { BottomPart } from './BottomPart'
import { Footer } from './Footer'
import logo from '../assets/powered_by_glimpse.png'
import mobileLogo from '../assets/email-logo_white.png'
import Api from '../utils/Api'

export class MainPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
      chartData: [],
      keywordArray: ['apple', 'microsoft'],
      smooth: true,
      query: 'apple,microsoft',
      errMsg: '',
      isChartOpen: true
    }

    this.api = new Api()
  }

  async componentDidMount() {
    if (this.props.embed === true) {
      const res = await this.api.fetchData(this.props.query)
      this.setState({ chartData: res })
    } else {
      this.handleSearch(this.props.query || 'apple,microsoft')
    }
  }

  setSmooth = () => {
    this.setState({ smooth: !this.state.smooth })
  }

  syncInput = (input) => {
    this.setState({ input })
  }

  handleSearch = async input => {
    this.setState({ chartData: [], errMsg: '' })

    const keywords = input.replace(/ +(?= )/g, '') // replace multiple spaces with a single space
      .split(', ').join(',')
      .split(' ,').join(',')
      .split(' ').join('%20')
    
    if (keywords !== '%20') {
      window.history.pushState('', 'Reddit Trends | Glimpse', '/' + keywords)

      const keywordArray = keywords.split('%20').join(' ').split(',')

      this.setState({ 
        keywordArray,
        query: keywords,
        chartData: []
      })
      
      const res = await this.api.fetchData(keywords)
      if (res.data !== undefined) {
        if (res.data.errors !== undefined) {
          const err = res.data.errors
          if (err.keywords !== undefined) {
            if (Array.isArray(err.keywords)) {
              this.setState({ errMsg: err.keywords + '.' })
            } else {
              this.setState({ errMsg: 'Keyword is invalid.' })
            }
          } 
        } else if (res.data.message !== undefined) {
          this.setState({ errMsg: 'There’s not enough data for your search!' })
        }
      } else {
        this.setState({
          chartData: res
        })
      }
    }
  }
  
  render() {
    if (this.props.embed === true) {
      const keywordArray = this.props.query.split('%20').join(' ').split(',')
      return (
        <ChartComponent 
          data={this.state.chartData}
          setSmooth={this.setSmooth}
          smooth={this.state.smooth}
          query={this.props.query}
          keywordArray={keywordArray}
          embed={true}
          errMsg={this.state.errMsg}
        />
      )
    }
    return (
      <Container>
        <a href='https://meetglimpse.com/'>
          <img src={logo} className='top-logo' alt='glimpse' />
        </a>
        <a href='https://meetglimpse.com/'>
          <img src={mobileLogo} className='mobile-top-logo' alt='glimpse' />
        </a>
        <Row className='title-row'>
          <Col>
            <h1 className='title'>
              Explore What the World&nbsp;
              <br className='title-break' />
              is Talking About on&nbsp;
              <span className='reddit'>Reddit</span>&trade;
            </h1>
          </Col>
        </Row>
        <SearchInput 
          handleSearch={this.handleSearch}
          syncInput={this.syncInput}
          input={this.state.input} 
        />
        <ChartComponent 
          data={this.state.chartData}
          setSmooth={this.setSmooth}
          smooth={this.state.smooth}
          query={this.state.query}
          keywordArray={this.state.keywordArray}
          errMsg={this.state.errMsg}
        />
        <Keywords 
          handleSearch={this.handleSearch}
          syncInput={this.syncInput}
          errMsg={this.state.errMsg}
        />
        <BottomPart />
        <Footer />
      </Container>
    )
  }
} 
