import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Subscribe } from './Subscribe'
import { keywords } from '../utils/keywords'


export class Keywords extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  handleClick = i => {
    const input = keywords[i]
    const editedInput = input.split(' and').join(',')
    this.props.syncInput(editedInput)
    this.props.handleSearch(editedInput)
  }

  renderMultiKeyword = (keywordString) => {
    return keywordString.replace('and', `<span class='and'>and</span>`)
  }
  
  render() {
    return (
      <div className='keyword-div'>
        {
          this.props.errMsg.length > 0 &&
          <Row>
            <h4 className='error-msg'>{`${this.props.errMsg} Try one of these:`}</h4>
          </Row>
        }
        <Row className='keyword-row'>
          <Col className='keyword-col'>
            {
              keywords.map((keyword, i) => {
                return (
                  <span className='keyword' key={keyword + i} onClick={() => this.handleClick(i)}>
                    { 
                      keyword.includes(' and ') ? 
                        <div dangerouslySetInnerHTML={{
                            __html: this.renderMultiKeyword(keyword)
                        }} /> 
                        : 
                        keyword
                    }
                  </span>
                )
              })
            }
          </Col>
        </Row>
        <Subscribe />
      </div>
    )
  }
}
